<template>
  <div>
    <div class="title">{{ $fanyi("注册方法") }}</div>
    <div class="margin-bottom-18">①新規会員の方はトップページより進んでください</div>
    <img class="gif15" src="../../../../assets/userGuide/15.gif"
         @click="imgFullscreen('.gif15')">
    <div style="margin: 42px 0 18px 0" class="lineHeight32">
      ①個人情報をご記入頂く際に、赤の★がついている項目は必ずご記入ください。<br>
      ②ご登録の携帯番号に会員登録用の確認コードが送信されます。日本国内の携帯電話のみ対応になっておりますので、間違いなくご記入ください。<br>
      ③問題なければラクマート利用規約とプライバシーポリシーに同意し、次へお進みください。
    </div>
    <img alt="" class="gif16" src="../../../../assets/userGuide/16.gif"
         @click="imgFullscreen('.gif16')">
    <div style="margin: 42px 0 18px 0">①　記入情報をご確認の上、「この内容で登録」ボタンをクリックしてください。</div>
    <img alt="" class="gif17" src="../../../../assets/userGuide/17.gif"
         @click="imgFullscreen('.gif17')">
  </div>
</template>

<script>
export default {
  methods: {
    imgFullscreen(val) {
      const el = document.querySelector(val); // 获取需要全屏的元素
      if (!document.fullscreenElement) { // 判断是否已经处于全屏模式
        el.requestFullscreen().catch(err => { // 进入全屏模式
          console.log(`进入全屏模式失败：${err.message}`);
        });
      } else {
        document.exitFullscreen(); // 退出全屏模式
      }
    }
  }
}
</script>

<style lang="scss" scoped>
div {
  color: #010101;
}

.title {
  color: #010101;
  font-weight: bold;
  font-size: 24px;
  border-bottom: 1px solid #DFDFDF;
  padding-bottom: 26px;
  margin-bottom: 32px;
}

.margin-bottom-18 {
  margin-bottom: 18px;
}

img {
  width: 983px;
  height: 496px;
}

.lineHeight32 {
  line-height: 32px;
}
</style>
