<template>
  <div>
    <div class="title">日本倉庫納品の流れ</div>
    <div class="fontWeightBold fontSize16" style="margin: 32px 0 18px 0">
      ①配送依頼書の提出
    </div>
    <div class="fontSize14">マイページに倉庫の商品を選択し、配送依頼書を提出します。</div>
    <img class="gif1" src="../../../../assets/userGuide/warehouseDeliveryProcess1.gif"
         style="margin:9px 0 36px" @click="imgFullscreen('.gif1')">
    <div class="fontSize14">ラベル情報欄をクリックし、日本各倉庫のラベル情報が編集できます。</div>
    <img class="gif2" src="../../../../assets/userGuide/warehouseDeliveryProcess2.gif"
         style="margin:9px 0 36px" @click="imgFullscreen('.gif2')">
    <div class="fontSize14">配送先が事前に不明の場合、先に仮配送先を入力しても大丈夫です。</div>
    <img class="gif3" src="../../../../assets/userGuide/warehouseDeliveryProcess3.gif"
         style="margin:9px 0 36px" @click="imgFullscreen('.gif3')">
    <div class="fontWeightBold fontSize16">②商品ラベルの送付</div>
    <div class="fontSize14" style="margin: 9px 0">
      ラクチャットまたはチャットワークで担当者に連絡し、商品ラベルのPDFを担当者に送付してください。
    </div>
    <div class="fontWeightBold fontSize14">
      商品ラベル規格：<span style="color:#FF0000;">A4版24面66*33.9㎜</span>
    </div>
    <div class="fontWeightBold fontSize14" style="margin-top:32px">ラクマートの操作 ：</div>
    <div class="lineHeight32 fontSize14">
      商品ラベルを貼り、見積もりを提出します。<br>
      担当者から配送依頼書を確認し、商品ラベルをチェックしてからプリントします。<br>
      ラベル作業員は商品ラベルを貼り付け作業を行い、梱包完了したら相応しい発送方法で配送依頼書の見積を提出します。<br>
      配送依頼書には、荷物の箱数、重量、及び段ボールのサイズが記載されます。
    </div>
    <div class="fontWeightBold fontSize16" style="margin-top:32px">③配送ラベルの送付</div>
    <div class="lineHeight32 fontSize14">商品の梱包状況を確認した上で、配送依頼書の商品梱包状況によって、配送ラベルの送付をお願いします。<br>
      注意：アマゾンでFBA配送ラベルを申請する際に、配送業者は「other」をご選択下さい。
    </div>
    <div class="fontWeightBold fontSize14">FBA配送ラベルの規格：<span style="color:#FF0000;">A4版6面99*105㎜</span></div>
    <div class="lineHeight32 fontSize14" style="margin-top:32px">
      TIPS：通関時必要の為、配送ラベル提出時に販売商品ページの画像も一緒に提供してください。<br>
      在庫切れの商品がある場合、在庫管理と納品プランの共有をお願いします。
    </div>
    <div class="fontWeightBold fontSize14" style="margin-top:32px">ラクマートの操作：</div>
    <div class="lineHeight32 fontSize14">
      配送依頼書のご入金を確認頂いた後、担当者は荷物のインボイスを作成し、システムに荷物の物流追跡番号を記入します。<br>
      配送ラベルを貼り、荷物を発送いたします。<br>
      各箱の箱ナンバーを基に配送ラベルを貼付し、荷物の発送手続きが完了になります。荷物の状況については、随時追跡番号で確認できます。
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    imgFullscreen(val) {
      const el = document.querySelector(val); // 获取需要全屏的元素
      if (!document.fullscreenElement) { // 判断是否已经处于全屏模式
        el.requestFullscreen().catch(err => { // 进入全屏模式
          console.log(`进入全屏模式失败：${err.message}`);
        });
      } else {
        document.exitFullscreen(); // 退出全屏模式
      }
    }
  }
}
</script>

<style lang="scss" scoped>
div {
  color: #010101;
}

.title {
  color: #010101;
  font-weight: bold;
  font-size: 24px;
  border-bottom: 1px solid #DFDFDF;
  padding-bottom: 26px;
  margin-bottom: 32px;
}

.newBtn {
  width: 160px;
  height: 32px;
  background: #B4272B;
  border-radius: 6px;
  line-height: 32px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 18px;
  color: #fff;
}

.margin-bottom-18 {
  margin-bottom: 18px;
}

img {
  width: 983px;
  height: 496px;
}

.lineHeight32 {
  line-height: 32px;
}
</style>
