<template>
  <div>
    <div class="title">{{ $fanyi("提出发货方法") }}</div>
    <div style="margin: 32px 0 18px 0" class="lineHeight32">
      ①倉庫より配送依頼可能商品を確認します。<br>
      ②発送したい商品を選択（チェックを入れる）します。<br>
      ③発送可能な数量が残り数量に更新されます。同じ商品を異なる配送先に送りたい場合は、それぞれ2回に分けて発送依頼をご提出されて下さい。<br>
      ④商品を選択した後、見積もり依頼を提出します。
    </div>
    <img alt="" class="distribution1" src="../../../../assets/userGuide/distribution1.gif"
         @click="imgFullscreen('.distribution1')">
    <img alt="" class="distribution2" src="../../../../assets/userGuide/distribution2.gif"
         @click="imgFullscreen('.distribution2')">
    <div style="margin: 42px 0 18px 0" class="lineHeight32">
      ①更に商品を追加したい場合は倉庫より商品の追加が可能です。<br>
      ②出荷数はデフォルトで倉庫の残り数量になりますが、数量の変更も可能です。<br>
      ③住所は輸入者と配送先に分かれ、輸入者は基本的にはお客様の住所または会社の住所（関税の請求など）であり、配送先は商品のお届け先です。
      <br>
      ④配送会社の選択は、「お任せ」を選択すると、発送方法は当社が決定します。<br>
      また、配送会社を指定されましても、一部のケースでは配送先の住所や商品内容により指定された配送会社を変更する事があります。<br>
      ⑤確認ができたら提出して下さい。
    </div>
    <img alt="" class="distribution3" src="../../../../assets/userGuide/distribution3.gif"
         @click="imgFullscreen('.distribution3')">
    <div style="margin: 42px 0 18px 0" class="lineHeight32">
      ①提出後、当社が商品の梱包等を行いますので、見積もり中になります。<br>
      ②梱包完了後、国際送料が確定し、送料のお支払い待ち状態になります。<br>
      ※配送依頼提出後の商品の追加や変更は出来ませんのでご注意下さい。<br>
      ※送料などに疑問がある場合は、担当者までご連絡ください。<br>
      ③お支払いが完了しましたら、商品を発送させて頂きます。
    </div>
    <img class="distribution4" src="../../../../assets/userGuide/distribution4.gif"
         @click="imgFullscreen('.distribution4')">
    <div style="margin: 42px 0 18px 0" class="lineHeight32">
      ①お支払い待ち状態の配送依頼書をクリックすると、今回の商品の重量と支払金額を確認できます。<br>
      ②「支払いへ」ボタンをクリックすると、入金報告画面に移り、入金報告提出と、お振込みをして頂ければ、弊社で入金確認後、商品を発送させて頂きます。
    </div>
    <img class="distribution5" src="../../../../assets/userGuide/distribution5.gif"
         @click="imgFullscreen('.distribution5')">
  </div>
</template>

<script>
export default {
  methods: {
    imgFullscreen(val) {
      const el = document.querySelector(val); // 获取需要全屏的元素
      if (!document.fullscreenElement) { // 判断是否已经处于全屏模式
        el.requestFullscreen().catch(err => { // 进入全屏模式
          console.log(`进入全屏模式失败：${err.message}`);
        });
      } else {
        document.exitFullscreen(); // 退出全屏模式
      }
    }
  }
}
</script>

<style lang="scss" scoped>
div {
  color: #010101;
}

.title {
  color: #010101;
  font-weight: bold;
  font-size: 24px;
  border-bottom: 1px solid #DFDFDF;
  padding-bottom: 26px;
  margin-bottom: 32px;
}

.margin-bottom-18 {
  margin-bottom: 18px;
}

img {
  width: 983px;
  height: 496px;
}

.lineHeight32 {
  line-height: 32px;
}
</style>
