<template>
  <div>
    <div class="title">仕入れの流れ</div>
    <div class="flex">
      <img src="../../../../assets/line.png" alt="">
      <div>
        <div class="one">
          <div class="flexAndCenter">
            <div>01</div>
            <div>商品をカートに追加、注文書を提出</div>
          </div>
          <div class="footerText">商品をキーワード検索またはアリババ、タオバオのURLで商品検索可能</div>
        </div>
        <div class="one">
          <div class="flexAndCenter">
            <div>02</div>
            <div>注文書を確認、見積提出</div>
          </div>
          <div class="footerText">担当者が商品価格と在庫などを確認し、見積りを提出させて頂きます。</div>
        </div>
        <div class="one">
          <div class="flexAndCenter">
            <div>03</div>
            <div>商品代金のお支払い</div>
          </div>
          <div class="footerText">商品の数量や単価を再確認し、問題なければお支払い</div>
        </div>
        <div class="one">
          <div class="flexAndCenter">
            <div>04</div>
            <div>買い付け＆商品が弊社倉庫へ到着</div>
          </div>
          <div class="footerText">商品を購入、セラーより商品が届くのを待ちます。</div>
        </div>
        <div class="one">
          <div class="flexAndCenter">
            <div>05</div>
            <div>倉庫内容を確認し、発送依頼を提出</div>
          </div>
          <div class="footerText">弊社倉庫に商品が届いたら、送り先と発送方法を指定してください。</div>
        </div>
        <div class="one">
          <div class="flexAndCenter">
            <div>06</div>
            <div>送料の見積り</div>
          </div>
          <div class="footerText">梱包サイズ・重量を確定し、国際送料見積りを提出</div>
        </div>
        <div class="one">
          <div class="flexAndCenter">
            <div>07</div>
            <div>国際配送料のお支払い</div>
          </div>
          <div class="footerText">問題なければ国際配送料をお支払いください。</div>
        </div>
        <div class="one">
          <div class="flexAndCenter">
            <div>08</div>
            <div>国際出荷</div>
          </div>
          <div class="footerText">基本入金確認後、当日に出荷させて頂きます。</div>
        </div>
        <div class="one">
          <div class="flexAndCenter" style="margin-bottom: 0">
            <div>09</div>
            <div>受け取り</div>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-left: 94px;margin-top: 18px">商品に問題がある場合は担当スタッフまでご連絡くだい。</div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
div {
  color: #010101;
}

.title {
  color: #010101;
  font-weight: bold;
  font-size: 24px;
  border-bottom: 1px solid #DFDFDF;
  padding-bottom: 26px;
  margin-bottom: 35px;
}

.one {
  margin-left: 21px;

  .flexAndCenter {
    margin-bottom: 18px;

    div:first-child {
      color: #B4272B;
      font-style: italic;
      font-size: 24px;
      margin-right: 19px;
    }

    div:last-child {
      color: #010101;
      font-weight: bold;
      font-size: 18px;
    }
  }

  .footerText {
    margin-left: 48px;
    margin-bottom: 45.6px;
    color: #010101;
  }
}
</style>
