<template>
  <div>
    <div class="title">{{ $fanyi("タオバオ、1688アカウント連結、初回ログイン＆パスワード設定") }}</div>
    <div class="fontSize18 fontWeightBold" style="margin: 32px 0 18px 0">連結について：</div>
    <div class="lineHeight32">
      ラクマートサイト登録後　通常代行サイトまたは無在庫サイトの下記URLより連結可能です <br>
      代行サイト：
      <el-link href="https://www.rakumart.com/accountSynchronization" target="_blank">
        https://www.rakumart.com/accountSynchronization
      </el-link>
      <br>
      無在庫サイト：
      <el-link href="https://www.rakumart-2c.com/#/accountSynchronization" target="_blank">
        https://www.rakumart-2c.com/#/accountSynchronization
      </el-link>
    </div>
    <div class="fontSize16 fontWeightBold" style="margin: 32px 0 18px 0">１．連結へボタンをクリック</div>
    <img alt="" class="gif1" src="../../../../assets/userGuide/accountConnectionImg.png"
         @click="imgFullscreen('.gif1')">
    <div class="fontSize16 fontWeightBold" style="margin: 32px 0 18px 0">２．上記に同意、次へクリック</div>
    <img alt="" class="gif2" src="../../../../assets/userGuide/accountConnectionImg_1.png"
         @click="imgFullscreen('.gif2')">
    <div class="fontSize16 fontWeightBold" style="margin: 32px 0 18px 0">３．携帯番号とメールアドレスを確認</div>
    <img alt="" class="gif3" src="../../../../assets/userGuide/accountConnectionImg_2.png"
         @click="imgFullscreen('.gif3')">
    <div class="fontSize16 fontWeightBold" style="margin: 32px 0 18px 0">４．認証コード確定後、連結完了</div>
    <img alt="" class="gif4" src="../../../../assets/userGuide/accountConnectionImg_3.png"
         @click="imgFullscreen('.gif4')">
    <div class="lineHeight32" style="color:#FF0000;margin-top: 18px">
      注意！！*失敗した場合は、ラクマート新規登録時に利用されてた携帯番号は既にタオバオまたはアリババアカウントに利用されたことがある場合が多く、別の携帯番号に変更して連結を行ってください。
    </div>
    <div class="fontSize18 fontWeightBold" style="margin-top: 32px">ログインについて</div>
    <div class="fontSize16 fontWeightBold" style="margin:12px 0">1.1688トップページ左上または下記のURLより</div>
    <el-link href="https://www.1688.com/" style="margin-bottom: 18px" target="_blank">https://www.1688.com/</el-link>
    <img alt="" class="gif5" src="../../../../assets/userGuide/accountConnectionImg_4.png"
         @click="imgFullscreen('.gif5')">
    <div class="fontSize16 fontWeightBold" style="margin: 32px 0 18px 0">２.短信登录（SMS認証コードログイン）を選択</div>
    <img alt="" class="gif6" src="../../../../assets/userGuide/accountConnectionImg_5.png"
         @click="imgFullscreen('.gif6')">
    <div class="fontWeightBold" style="margin: 18px 0;font-size: 14px">
      注意：携帯番号について、一つ目の０は必ずご記入ください
    </div>
    <div class="fontSize16 fontWeightBold" style="margin-bottom:18px">
      ３．ログイン後、タオバオサイトについて、『トップページへ』になりますが、１６８８の場合は下記の画面が表示されます。
    </div>
    <img alt="" class="gif7" src="../../../../assets/userGuide/accountConnectionImg_6.png"
         @click="imgFullscreen('.gif7')">
    <div class="fontSize16 fontWeightBold" style="margin: 32px 0 18px 0">４．不再提醒をクリックして完了</div>
    <div class="lineHeight32" style="color:#FF0000;">
      注意：弊社連結の場合タオバオアリババに関わらずユーザー名は弊社サイトで登録された本名＋番号＋rakumartjpの形になっており、違う場合は必ずご連絡ください。
    </div>
    <div class="fontSize18 fontWeightBold" style="margin: 32px 0 18px 0">パスワード設定について</div>
    <div class="fontSize16 fontWeightBold" style="margin-bottom:18px">タオバオログイン後、左上の（账号管理）</div>
    <img alt="" class="gif8" src="../../../../assets/userGuide/accountConnectionImg_7.png"
         @click="imgFullscreen('.gif8')">
    <div class="fontWeightBold fontSize14" style="margin:18px 0">
      <div style="margin-bottom: 9px">↓①は今後ログイン用のユーザー名になります。↓</div>
      <div>（<span style="color:#FF0000;">変更できませんので、必ず別途保存をお願いします。</span>）</div>
    </div>
    <div class="fontWeightBold fontSize14">②（修改）をクリック</div>
    <img alt="" class="gif9" src="../../../../assets/userGuide/accountConnectionImg_8.png"
         style="margin:18px 0" @click="imgFullscreen('.gif9')">
    <div class="fontWeightBold fontSize14">現在のアカウント環境の自動認証、（知道了）をクリック</div>
    <img alt="" class="gif10" src="../../../../assets/userGuide/accountConnectionImg_9.png"
         style="margin:18px 0" @click="imgFullscreen('.gif10')">
    <div class="lineHeight32 fontWeightBold fontSize14">
      １，新規パスワードを記入<br>
      ２，新規パスワード再記入確認<br>
      確定　をクリックして完了
    </div>
    <img alt="" class="gif11" src="../../../../assets/userGuide/accountConnectionImg_10.png"
         style="margin:18px 0" @click="imgFullscreen('.gif11')">
    <div class="fontWeightBold fontSize14">完了ページが表示されましたら、設定完了。</div>
    <div class="lineHeight32 fontWeightBold fontSize14" style="margin-top: 18px">
      自動ログアウトされている状態になりますので、<br>
      先ほど記録したユーザー名と設定されたパスワードでログイン可能になります。
    </div>
    <img alt="" class="gif12" src="../../../../assets/userGuide/accountConnectionImg_11.png"
         style="margin-top:18px" @click="imgFullscreen('.gif12')">
    <div class="fontWeightBold fontSize18" style="margin: 32px 0 18px 0">１６８８の場合</div>
    <div class="fontWeightBold fontSize16">１．账号管理をクリック</div>
    <img alt="" class="gif13" src="../../../../assets/userGuide/accountConnectionImg_12.png"
         style="margin:18px 0" @click="imgFullscreen('.gif13')">
    <div class="fontWeightBold fontSize16">２．账号安全 を選択し、下記のところよりタオバオサイト同様修正可能</div>
    <img alt="" class="gif14" src="../../../../assets/userGuide/accountConnectionImg_13.png"
         style="margin:18px 0 32px" @click="imgFullscreen('.gif14')">
    <div class="fontWeightBold fontSize14" style="color:#FF0000;">
      タオバオと１６８８は同じユーザー名とパスワードを利用しているため、片方のみ修正して頂ければ問題ありません。<br>
      よろしくお願い致します。
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    imgFullscreen(val) {
      const el = document.querySelector(val); // 获取需要全屏的元素
      if (!document.fullscreenElement) { // 判断是否已经处于全屏模式
        el.requestFullscreen().catch(err => { // 进入全屏模式
          console.log(`进入全屏模式失败：${err.message}`);
        });
      } else {
        document.exitFullscreen(); // 退出全屏模式
      }
    }
  }
}
</script>

<style lang="scss" scoped>
div {
  color: #010101;
}

.title {
  color: #010101;
  font-weight: bold;
  font-size: 24px;
  border-bottom: 1px solid #DFDFDF;
  padding-bottom: 26px;
  margin-bottom: 32px;
}

.margin-bottom-18 {
  margin-bottom: 18px;
}

img {
  width: 983px;
  height: 496px;
}

.lineHeight32 {
  line-height: 32px;
}
</style>
