<template>
  <div>
    <div class="title">チャットの使用方法</div>
    <div class="lineHeight32" style="margin-bottom:18px">
      ①トップページの右側にチャットをクリックし、ラクチャットが開けます。
    </div>
    <img alt="" class="picture1" src="../../../../assets/userGuide/picture1.png" @click="imgFullscreen('.picture1')">
    <div class="lineHeight32" style="margin: 42px 0 18px 0">
      ②ラクチャットの左側に会話欄があり、ラクマート専属担当者と連絡可能です。<br>
      ラクチャットの右上に、「…」をクリックすると、チャットグループのメンバーが確認できます。<br>
      会話欄の下に「お知らせへ」と「買い物かごへ」のアイコンがあります。
    </div>
    <div class="lineHeight32" style="margin: 42px 0 18px 0">
      ③チャットのエディットボックスに文字入力、又は画像やCSV、PDF等のデータをドロップしてから送信をクリックしてください。<br>
      送信完了後、メッセージに右クリックすれば、コピー、送信取り消しや引用の機能が利用可能です。
    </div>
    <img alt="" class="picture2" src="../../../../assets/userGuide/picture2.png" @click="imgFullscreen('.picture2')">
    <div class="lineHeight32" style="margin-bottom:18px">
      エディットボックスの右上に、「チャット履歴」をクリックすると、過去のチャット履歴が確認できます。
    </div>
    <img alt="" class="picture3" src="../../../../assets/userGuide/picture3.png" @click="imgFullscreen('.picture3')">
    <div class="lineHeight32" style="margin: 42px 0 18px 0">
      ④ラクチャットの右側には注文書と問題商品が表示されます。<br>
      また、下に検索欄があり、注文書番号を入力してから検索してください。
    </div>
    <div class="lineHeight32" style="margin-bottom:18px">
      注文書欄に全ての注文書が一覧表示され、状態による絞り込みが可能です。<br>
      注文書番号をクリックすると、注文詳細ページに移動します。<br>
      送信ボタンを押し、注文書番号を会話欄に送信します。<br>
      送信ボタンの後ろに備考アイコンがあり、注文書に関するメモを直接ラクチャットに備考可能です。<br>
      注文書を展開してからは、注文した商品の詳細も確認できます。<br>
      選択ボタンを押し、「商品情報を送信」、「買い物かごへ追加」と「注文へ」三つの操作ボタンがあります。<br>
      商品情報を送信：選択した商品情報を会話欄に送信します。<br>
      買い物かごへ追加：選択した商品を買い物かごへ追加します。
    </div>
    <div class="lineHeight32" style="margin-bottom:18px">
      注文へ：選択した商品で注文書ページを開く、「注文を提出」をクリックすれば、注文書を作成します。
    </div>
    <img alt="" class="picture4" src="../../../../assets/userGuide/picture4.png" @click="imgFullscreen('.picture4')">
    <div class="lineHeight32" style="margin: 42px 0 18px 0">
      問題商品欄の数字は、問題商品ありの注文書の件数です。<br>
      注文書番号をクリックすると、問題商品ページに移動します。<br>
      注文書を展開してから問題の詳細が確認できます。<br>
      送信ボタンを押し、問題商品の番号を会話欄に送信します。<br>
    </div>
    <img alt="" class="picture5" src="../../../../assets/userGuide/picture5.png" style="margin-bottom: 20px"
         @click="imgFullscreen('.picture5')">
    <img alt="" class="picture6" src="../../../../assets/userGuide/picture6.png" @click="imgFullscreen('.picture6')">
  </div>
</template>

<script>
export default {
  methods: {
    imgFullscreen(val) {
      const el = document.querySelector(val); // 获取需要全屏的元素
      if (!document.fullscreenElement) { // 判断是否已经处于全屏模式
        el.requestFullscreen().catch(err => { // 进入全屏模式
          console.log(`进入全屏模式失败：${err.message}`);
        });
      } else {
        document.exitFullscreen(); // 退出全屏模式
      }
    }
  }
}
</script>

<style lang="scss" scoped>
div {
  color: #010101;
}

.title {
  color: #010101;
  font-weight: bold;
  font-size: 24px;
  border-bottom: 1px solid #DFDFDF;
  padding-bottom: 26px;
  margin-bottom: 32px;
}

.margin-bottom-18 {
  margin-bottom: 18px;
}

img {
  width: 983px;
  height: 496px;
}

.lineHeight32 {
  line-height: 32px;
}
</style>
