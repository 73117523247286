<template>
  <div>
    <div class="title">{{ $fanyi("下单方法") }}</div>
    <div class="fontWeightBold">商品ページ</div>
    <div style="margin: 32px 0 18px 0" class="lineHeight32">
      ①商品ページよりカラー、サイズと数量を指定します。<br>
      ②「ショッピングカートに入れる」ボタンをクリックすれば追加完了になります。<br>
      ③買い物かごのアイコンを押すと追加済みの商品を確認できます。<br>
      ④セール中の場合、Rakumart表記価格と異なる場合があります。その場合、見積り時に修正させて頂きます。金額が異なる理由は、タイムラグの問題やセール価格は反映されない事があります。
    </div>
    <img class="gif1" src="../../../../assets/userGuide/orderMethod1.gif" @click="imgFullscreen('.gif1')">
    <div style="margin: 42px 0 18px 0" class="lineHeight32">
      <div class="fontWeightBold">買い物かごページ</div>
      ①右側の買い物かごマークをクリック、買い物かごが開けます。<br>
      ②買い物かごに表示された数字は、追加された商品の項目数です。プログラムによるデータ処理の遅れが無いように、1件の注文書にアップする商品は100項目までとさせて頂きます。<br>
      （注文書が分かれても、同枠発送は可能です。）<br>
      ③買い物かごに於いては商品の数量及びカラーやサイズ等パリエーションは自由に変更可能です。商品詳細欄に「編集」文字を押すとカラーやサイズ等編集可能です。<br>
      ④確認した後、「注文へ」ボタンを押します。
    </div>
    <img class="gif2" src="../../../../assets/userGuide/orderMethod2.gif" @click="imgFullscreen('.gif2')">
    <div style="margin: 42px 0 18px 0" class="lineHeight32">
      <div class="fontWeightBold">注文書ページ</div>
      ①注文書ページでは注文数、カラーやサイズ等は変更可能です、また、各商品毎にオプションサービスの依頼を追加する事も出来ます、オプション内に無いサービスを受けたい場合は備考欄に記入して頂き、別途承る事も可能です。
      <br>
      ②FBAサービスをご利用する場合、ラベル編集欄の情報をご記入下さい。鉛筆のマークをクリック、ラベル編集のポップアップが出てきます。記入完了後、「保存」を忘れないようご注意下さい。
      <br>
      ③注文書を作成する際に、買い物かごや商品庫より、予め保存した商品を直接導入する事は可能です。
      <br>
      ④注文書ページに国際配送業者は指定可能です。また、特別な指示や注意点があれば、注文書の備考欄にご記入頂けると幸いです。
      <br>
      ⑤注文書の作成が完了後、直ちに「注文を提出」するか、「一時保存」も選択可能です。
      一時保存の場合は、提出とはなりませんので、ご安心下さい。
    </div>
    <img class="gif3" src="../../../../assets/userGuide/orderMethod3.gif" @click="imgFullscreen('.gif3')">
    <img class="gif4" src="../../../../assets/userGuide/orderMethod4.gif" @click="imgFullscreen('.gif4')">
  </div>
</template>

<script>
export default {
  methods: {
    imgFullscreen(val) {
      const el = document.querySelector(val); // 获取需要全屏的元素
      if (!document.fullscreenElement) { // 判断是否已经处于全屏模式
        el.requestFullscreen().catch(err => { // 进入全屏模式
          console.log(`进入全屏模式失败：${err.message}`);
        });
      } else {
        document.exitFullscreen(); // 退出全屏模式
      }
    }
  }
}
</script>

<style lang="scss" scoped>
div {
  color: #010101;
}

.title {
  color: #010101;
  font-weight: bold;
  font-size: 24px;
  border-bottom: 1px solid #DFDFDF;
  padding-bottom: 26px;
  margin-bottom: 32px;
}

.margin-bottom-18 {
  margin-bottom: 18px;
}

img {
  width: 983px;
}

.lineHeight32 {
  line-height: 32px;
}
</style>
