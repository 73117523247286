<template>
  <div>
    <div class="title">#注文サポートプログラム#</div>
    <div style="margin: 32px 0 18px 0" class="lineHeight32">
      *本拡張機能は Rakumart 代行サイトの検索時のコピー貼り付けの時間を短縮する機能になります。
    </div>
    <img class="gif18" src="../../../../assets/userGuide/18.jpg" @click="imgFullscreen('.gif18')">
    <div style="margin: 32px 0 18px 0">
      <!--      ①下記の URL（chrome ウェブストア）よりプログラム追加-->
      ①必要の場合は担当者まで申し付けください。
    </div>
    <div class="newBtn" @click="pageJump">ダウンロードへ</div>
    <!--    <img class="gif19" src="../../../../assets/userGuide/19.gif" @click="imgFullscreen('.gif19')">-->
    <!--    <div style="margin-top: 32px" class="lineHeight32">-->
    <!--      ②中国アリババまたはタオバオ詳細画面でRakumart詳細へボタン、買い物かごへ追加、画像検索三個のボタンを機能別でご利用可能。<br>-->
    <!--      <span class="colorB4272B">＊注文へは単品注文時にご利用可能ですが、</span><br>-->
    <!--      <span class="colorB4272B">1.注文、多数の場合は買い物かごへ追加してから注文書を作成してください。</span>-->
    <!--    </div>-->
    <!--    <div class="colorB4272B" style="margin: 32px 0 18px 0">＊問題ある際は担当者までご連絡ください。</div>-->
    <!--    <img alt="" class="gif20" src="../../../../assets/userGuide/20.gif" @click="imgFullscreen('.gif20')">-->
    <div style="margin-bottom:16px">1.デベロッパーモードをオンに切り替え</div>
    <img class="gif19" src="../../../../assets/userGuide/17001016249582.png"
         @click="imgFullscreen('.gif19')">
    <div style="margin: 16px 0">2.ファイルを選択して、真ん中へドロップする</div>
    <img class="gif20" src="../../../../assets/userGuide/1700101648472.png"
         @click="imgFullscreen('.gif20')">
    <div style="margin: 16px 0">3.下記の一覧表に表示されましたらインソール完了</div>
    <img class="gif21" src="../../../../assets/userGuide/17001016003394.png"
         @click="imgFullscreen('.gif21')">
  </div>
</template>

<script>
export default {
  methods: {
    pageJump() {
      // window.open('https://chrome.google.com/webstore/detail/rakumart%E4%BB%A3%E8%A1%8C%E3%82%B5%E3%83%9D%E3%83%BC%E3%83%88new/kiigkmeopkjhbpoijmdkolcpiaibfjjg?utm_source=ext_sidebar&hl=ja');
      window.open('https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202407/6682662ec65b9.zip');
    },
    imgFullscreen(val) {
      const el = document.querySelector(val); // 获取需要全屏的元素
      if (!document.fullscreenElement) { // 判断是否已经处于全屏模式
        el.requestFullscreen().catch(err => { // 进入全屏模式
          console.log(`进入全屏模式失败：${err.message}`);
        });
      } else {
        document.exitFullscreen(); // 退出全屏模式
      }
    }
  }
}
</script>

<style lang="scss" scoped>
div {
  color: #010101;
}

.title {
  color: #010101;
  font-weight: bold;
  font-size: 24px;
  border-bottom: 1px solid #DFDFDF;
  padding-bottom: 26px;
  margin-bottom: 32px;
}

.newBtn {
  width: 160px;
  height: 32px;
  background: #B4272B;
  border-radius: 6px;
  line-height: 32px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 18px;
  color: #fff;
}

.margin-bottom-18 {
  margin-bottom: 18px;
}

img {
  width: 983px;
  height: 496px;
}

.lineHeight32 {
  line-height: 32px;
}
</style>
