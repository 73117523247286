<template>
  <div>
    <div class="title">{{ $fanyi("商品查找方法") }}</div>
    <div style="margin: 32px 0 18px 0" class="lineHeight32">
      ①商品名キーワードで直接検索 <br>
      ②タオバオ、アリババURLの直接入力からの検索。<br>
      ③検索しても表示がでなかったり、表示された商品がキーワードと明らかに違ったりする場合は恐れ入りますが、ご報告を賜りますようお願いいた
      します。
    </div>
    <img alt="" class="gif9" src="../../../../assets/userGuide/9.gif" @click="imgFullscreen('.gif9')">
  </div>
</template>

<script>
export default {
  methods: {
    imgFullscreen(val) {
      const el = document.querySelector(val); // 获取需要全屏的元素
      if (!document.fullscreenElement) { // 判断是否已经处于全屏模式
        el.requestFullscreen().catch(err => { // 进入全屏模式
          console.log(`进入全屏模式失败：${err.message}`);
        });
      } else {
        document.exitFullscreen(); // 退出全屏模式
      }
    }
  }
}
</script>

<style lang="scss" scoped>
div {
  color: #010101;
}

.title {
  color: #010101;
  font-weight: bold;
  font-size: 24px;
  border-bottom: 1px solid #DFDFDF;
  padding-bottom: 26px;
  margin-bottom: 32px;
}

.margin-bottom-18 {
  margin-bottom: 18px;
}

img {
  width: 983px;
  height: 496px;
}

.lineHeight32 {
  line-height: 32px;
}
</style>
