<template>
  <div>
    <div class="title">入金報告の提出手順</div>
    <div class="lineHeight32" style="margin-bottom:18px">
      <p class="fontWeightBold" style="font-size: 19px">チャージの場合</p>
      <br>
      <p class="fontWeightBold" style="font-size: 17px">PAYPAL入金</p>
      ①マイページにある「チャージ」ボタンをクリックし、振込先情報が表示されます。
    </div>
    <img alt="" class="picture1" src="../../../../assets/userGuide/procedureForSubmittingAPaymentReport1.gif"
         @click="imgFullscreen('.picture1')">
    <div class="lineHeight32" style="margin: 42px 0 18px 0">
      ②銀行振込かPAYPAL入金かお選び頂けます。
    </div>
    <img alt="" class="picture2" src="../../../../assets/userGuide/procedureForSubmittingAPaymentReport2.gif"
         @click="imgFullscreen('.picture2')">
    <div class="lineHeight32" style="margin: 42px 0 18px 0">
      paypal入金の場合、4％の手数料が必要となりますので、予めご了承ください。<br>
      ③請求料金項目に金額を入力した後、「paypalへ」のボタンをクリックし、paypalの支払い画面に変り、表示された操作手順に従ってご入金ください。
    </div>
    <img alt="" class="picture3" src="../../../../assets/userGuide/procedureForSubmittingAPaymentReport3.jpg"
         style="height: 600px" @click="imgFullscreen('.picture3')">
    <div class="lineHeight32" style="margin: 42px 0 18px 0">
      <p class="fontWeightBold" style="font-size: 17px">銀行振込入金</p>
      ①楽天銀行、ゆうちょ銀行、paypay銀行からお選び頂けます。振込先銀行を選択して頂くと、口座情報が表示されます。
    </div>
    <img alt="" class="picture4" src="../../../../assets/userGuide/procedureForSubmittingAPaymentReport4.gif"
         @click="imgFullscreen('.picture4')">
    <div class="lineHeight32" style="margin-bottom:18px">
      ②着金予定日、入金名義、入金額項目に正しい情報をご入力後、「提出」ボタンをクリックし、入金報告を提出します。
    </div>
    <img alt="" class="picture5" src="../../../../assets/userGuide/procedureForSubmittingAPaymentReport5.jpg"
         @click="imgFullscreen('.picture5')">
    <div class="lineHeight32" style="margin-bottom:42px">
      ③お客様のご利用されている銀行から振込んで頂きます。振込際に、必ず入金報告時にご記入頂いた金額を、同じ名義の口座から、入金報告時に選んで頂いた弊社の銀行に振込んで頂くようにお願い致します。
    </div>
    <div style="font-weight: bold;font-size: 19px">注文書と配送依頼書の場合</div>
    <div class="lineHeight32" style="margin: 42px 0 18px 0">
      <p style="font-weight: bold;font-size: 17px">注文書からのご入金</p>
      ①すべての注文書項目より支払い待ち状態の見積書をご確認ください。
    </div>
    <img alt="" class="picture6" src="../../../../assets/userGuide/procedureForSubmittingAPaymentReport6.gif"
         @click="imgFullscreen('.picture6')">
    <div class="lineHeight32" style="margin: 42px 0 18px 0">
      ②注文番号または赤い「支払い」ボタンをクリックし、注文詳細ページに入ります。
    </div>
    <img alt="" class="picture7" src="../../../../assets/userGuide/procedureForSubmittingAPaymentReport7.gif"
         @click="imgFullscreen('.picture7')">
    <div class="lineHeight32" style="margin: 42px 0 18px 0">
      ③注文詳細の情報を確認した上、右下の赤い「支払い」ボタンをクリックし、入金報告画面が表示されます。
    </div>
    <img alt="" class="picture8" src="../../../../assets/userGuide/procedureForSubmittingAPaymentReport8.gif"
         @click="imgFullscreen('.picture8')">
    <div class="lineHeight32" style="margin: 42px 0 18px 0">
      残高が足りる場合、残金支払い、銀行入金とpaypal三つの送金方法が表示されます。 残高支払いをご希望する際は赤い「支払い」ボタンをクリックし、支払い完了します。
    </div>
    <img alt="" class="picture9" src="../../../../assets/userGuide/procedureForSubmittingAPaymentReport9.gif"
         @click="imgFullscreen('.picture9')">
    <img alt="" class="picture10" src="../../../../assets/userGuide/procedureForSubmittingAPaymentReport10.jpg"
         @click="imgFullscreen('.picture10')">
    <div class="lineHeight32" style="margin-bottom:18px">
      残高が足りない場合、銀行入金とpaypalの二つのみ表示されます。<br>
      <br>
      <br>
      <p style="font-weight: bold;font-size: 17px">paypal入金</p>
      請求料金項目に見積金額を入力した後、「paypal」ボタンをクリックし、paypalの支払い画面に変り、表示された操作手順に従ってご入金ください。
    </div>
    <img alt="" class="picture11" src="../../../../assets/userGuide/procedureForSubmittingAPaymentReport11.gif"
         @click="imgFullscreen('.picture11')">
    <img alt="" class="picture12" src="../../../../assets/userGuide/procedureForSubmittingAPaymentReport12.jpg"
         style="height: 600px" @click="imgFullscreen('.picture12')">
    <div class="lineHeight32" style="margin: 42px 0 18px 0">
      <p style="font-weight: bold;font-size: 17px">銀行振込入金</p>
      ①楽天銀行、ゆうちょ銀行、paypay銀行からお選び頂けます。振込先銀行を選択して頂くと、口座情報が表示されます。
    </div>
    <img alt="" class="picture13" src="../../../../assets/userGuide/procedureForSubmittingAPaymentReport13.gif"
         @click="imgFullscreen('.picture13')">
    <div class="lineHeight32" style="margin: 42px 0 18px 0">
      ②着金予定日、入金名義、入金額項目に正しい情報をご入力後、「提出」ボタンをクリックし、入金報告を提出します。
    </div>
    <img alt="" class="picture14" src="../../../../assets/userGuide/procedureForSubmittingAPaymentReport14.jpg"
         @click="imgFullscreen('.picture14')">
    <div class="lineHeight32" style="margin: 42px 0 18px 0">
      ③お客様のご利用されている銀行から振込んで頂きます。振込際に、必ず入金報告時にご記入頂いた金額を、同じ名義の口座から、入金報告時に選んで頂いた弊社の銀行に振込んで頂くようにお願い致します。
    </div>
    <div class="lineHeight32" style="color: #e60000">
      入金報告を提出しただけではまだご入金は完了しておりません。<br>
      入金報告時に選んで頂いた銀行にお振込みをお願い致します。<br>
      ※必ず同じ名義、同じ額を振込むようにお願い致します。差異が生じた場合入金反映されない可能性がございます、ご注意ください。<br>
      ※入金報告とお振込みは同じタイミング（連続）で行って頂くようにお願い致します。時間が経過しますと、為替変動による残高不足で注文や発送が実行できません。
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    imgFullscreen(val) {
      const el = document.querySelector(val); // 获取需要全屏的元素
      if (!document.fullscreenElement) { // 判断是否已经处于全屏模式
        el.requestFullscreen().catch(err => { // 进入全屏模式
          console.log(`进入全屏模式失败：${err.message}`);
        });
      } else {
        document.exitFullscreen(); // 退出全屏模式
      }
    }
  }
}
</script>

<style lang="scss" scoped>
div {
  color: #010101;
}

.title {
  color: #010101;
  font-weight: bold;
  font-size: 24px;
  border-bottom: 1px solid #DFDFDF;
  padding-bottom: 26px;
  margin-bottom: 32px;
}

.margin-bottom-18 {
  margin-bottom: 18px;
}

img {
  width: 983px;
}

.lineHeight32 {
  line-height: 32px;
}
</style>
